import { MapLocation } from '@/core/real-estate/typings'

export const API_KEY = process.env['NEXT_PUBLIC_GOOGLE_MAPS_API_KEY']

export const DEFAULT_ZOOM = 4
export const MAP_DEBOUNCE = 300

export const SEARCH_ITEM_SIZE = '250px'

export const NAVBAR_HEIGHT = '6vh'
export const SEARCHBAR_HEIGHT = '10vh'
export const CONTENT_HEIGHT = '84vh'

export const DEFAULT_RADIUS = 3553615

export const MEXICO_CITY_COORDINATES = [-99.20298022322095, 19.425140572909644] as MapLocation
export const EXCEL_ROW_COLOR = {
  type: 'pattern',
  pattern: 'solid',
  bgColor: { argb: '000047AB' },
  fgColor: { argb: '000047AB' }
}
export const EXCEL_CELL_COLOR = {
  type: 'pattern' as const,
  pattern: 'solid',
  fgColor: { argb: 'FFD3D3D3' }
}
export const EXCEL_HEADER_ALIGNMENT = {
  horizontal: 'center',
  vertical: 'middle'
}

export const EXCEL_ROW_ALIGNMENT = {
  vertical: 'middle'
}

export const EXCEL_HEADER_FONT = {
  bold: true,
  color: {
    argb: 'FFFFFFFF'
  },
  size: 14
}

export const EXCEL_DEFAULT_ROW_HEIGHT = 22
export const EXCEL_DEFAULT_HEADER_HEIGHT = 30

export const EXCEL_EVEN_ROW_FILL = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFF8F8F8' }
}

export const AREA_MAX_DISTANCE = 100000

export const SLUGIFY_OPTS = {
  lower: true,
  locale: 'es',
  strict: true
}

export const MEXICAN_STATES = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Chiapas',
  'Chihuahua',
  'CDMX',
  'Coahuila',
  'Colima',
  'Durango',
  'Estado de México',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas'
] as const
